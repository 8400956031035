import { createApp } from 'vue';
import App from './App.vue';

import ElementUI from 'element-plus';
import 'element-plus/theme-chalk/index.css';
import router from './router';
import i18n from './locales';
import SvgLeft from './assets/SvgLeft.vue';
import SvgRight from './assets/SvgRight.vue';
import SvgThank from './assets/SvgThank.vue';
import SvgDelete from './assets/SvgDelete.vue';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale'; //
import localeData from 'dayjs/plugin/localeData';

var app = createApp(App);

// 图标
app.component('SvgLeft', SvgLeft);
app.component('SvgRight', SvgRight);
app.component('SvgThank', SvgThank);
app.component('SvgDelete', SvgDelete);

app.use(router);
app.use(i18n);
app.use(ElementUI);
app.mount('#app');

dayjs.extend(updateLocale);
dayjs.extend(localeData);
dayjs.locale('en');
dayjs.updateLocale('en', {
    weekStart: 1 // 配置为周一
});

const debounce = (fn, delay) => {
    let timer = null;
    return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    };
};

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
    }
};
